
  .logoSpin{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: 60px;
    height: 60px;
    animation: logo 2s ease-out infinite;
  }
  
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    display: inline-block;
    width: 70px;
    height: 70px;
    margin: 0 auto;
    color: inherit;
    pointer-events: none;
    border: 1px solid black;
    border-radius: 50%;
    animation: loader 1s ease-out infinite;
  }
  
  @keyframes loader {
    0% {
      transform-origin: 0 0;
      transform: scale(1) translate(-50%, -50%);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform-origin: 0 0;
      transform: scale(2) translate(-50%, -50%);
      opacity: 0;
    }
  }
  
  @keyframes logo {
    from {
      transform-origin: 0 0;
      transform: scale(1) translate(-50%, -50%);
    }
    50% {
      transform-origin: 0 0;
      transform: scale(1.1) translate(-50%, -50%);
    }
    to {
      transform-origin: 0 0;
      transform: scale(1) translate(-50%, -50%);
    }
  }