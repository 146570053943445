.App {
  text-align: center;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200&family=Rubik:wght@400;500;700&display=swap");

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.cover {
  background-image: url("../img/DSC_0079.jpg");
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .title h1 {
    font-size: 20px;
    margin: 10px;
  }
}

@media (max-width: 480px) {
  .title h1 {
    font-size: 18px;
    margin: 5px;
  }
}

.podnaslov {
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-size: 16px;
  text-transform: uppercase;
  /* margin-top: 0px; */
  animation: zoom-in 1s ease-out;
  color: white;
}

@media (max-width: 768px) {
  .podnaslov {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .podnaslov {
    font-size: 12px;
  }
}

.hrline {
  animation: side-zoom 1s ease;

  border: 0;
  height: 1px;
  width: 50%;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0)
  );
}

@keyframes side-zoom {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

/*---------------------------about-----------------------------*/

.about {
  /* background-image: url("../img/DSC_1483.jpg"); */
  background-size: cover;
  height: 500px;
  color: #7a7a7a;
}

.aboutLayer {
  background-color: rgba(249, 249, 247, 0.8);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.aboutLayer > p {
  width: 400px;
}

@media (min-width: 481px) and (max-width: 768px) {
  .about {
    height: 500px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .about {
    height: 400px;
    font-size: 14px;
  }
}

.about > p {
  width: 700px;
  padding-top: 20px;
}

@media (min-width: 481px) and (max-width: 768px) {
  .about > p {
    width: 300px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .about > p {
    width: 300px;
    font-size: 13px;
  }
}

/*--------------------------------------------------------*/

.printButton {
  background: none;
  border: 1px solid white;
  height: 50px;
  width: 150px;
  color: white;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 100px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .printButton {
    height: 40px;
    width: 100px;
    font-size: 12px;
  }
}
.printButton:hover {
  background-color: black;
  color: white;
  transition: all 0.3s;
  cursor: pointer;
}

.albumPrintButton {
  background: none;
  border: 1px solid white;
  height: 50px;
  width: 150px;
  color: white;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 60px;
}

.albumPrintButton:hover {
  background-color: black;
  color: white;
  transition: all 0.3s;
  cursor: pointer;
}

/* -------------------slike------------------ */

.photo {
  height: 230px;
  width: 340px;
  display: flex;
  overflow: hidden;
}

@media (min-width: 320px) and (max-width: 480px) {
  .photo {
    height: 80px;
    width: 110px;
    display: flex;
    overflow: hidden;
  }
}

.photo:hover img {
  transform: scale(1.1);
  opacity: 0.5;
  transition: all 0.8s; /* gives slower time transition on effects when hover  */
  /* set lover opacity after hovering (gives white color) */
}
.photo img {
  height: 100%;
  width: 100%;
  display: block;
  object-fit: contain;
  /* set initial opacity  */
}

.gallery {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media (min-width: 320px) and (max-width: 480px) {
  .gallery {
    display: flex;
  }
}

.photoContainer {
  height: auto;
  width: auto;
  margin: 2px 4px 2px 4px;
  cursor: pointer;
}

@media (min-width: 320px) and (max-width: 480px) {
  .photoContainer {
    height: 100px;
    width: auto;
  }
}

.navBar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 80px;
  color: white;
  font-weight: lighter;
  background-color: transparent;
  position: absolute;
  left: 50%;
  top: 5%;
  transform: translate(-50%, -50%);
}

@media (min-width: 320px) and (max-width: 480px) {
  .navBar {
    display: none;
  }
}

h1 {
  color: white;
  font-family: "Montserrat";
  font-weight: 100;
  font-size: 30px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 2px;
  animation: zoom-in 1s ease-out;
  margin: 20px;
}

@keyframes zoom-in {
  0% {
    transform: scale(0.4);
  }
  100% {
    transform: scale(1);
  }
}

h3 {
  font-family: "Merriweather", Sans-serif;
  color: #7a7a7a;
  font-weight: 100;
  font-size: 20px;
}

/*----------------------galleryBackground----------------------------------*/
.galleryBackground {
  /* Just a background photo for all 3 div in link gallery  */
  background-image: url(../img/DSC_00871.jpg);
  height: 600px;
  background-size: cover;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

@media (min-width: 481px) and (max-width: 768px) {
  .galleryBackground {
    height: 1020px;
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .galleryBackground {
    height: 1000px;
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .galleryBackground h5 {
    display: none;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .galleryBackground h5 {
    display: none;
  }
}
.galleryBackground div h3 {
  /* style for h3 inside link gallery only  */
  color: white;
}

/*---------------------------viewGallery-----------------------------*/

.viewGallery {
  /* view gallery buttons  */
  background: none;
  border: 1px solid white;
  height: 40px;
  width: 120px;
  color: white;
  font-family: "montserrat";
  font-weight: 100;
  position: relative;
  top: 35px;
}

.viewGallery:hover {
  cursor: pointer;
  background-color: rgb(225, 225, 225, 0.9);
  color: #7a7a7a;
  transition: all 0.3s;
}

@media (min-width: 320px) and (max-width: 480px) {
  .viewGallery {
    height: 35px;
    width: 100px;
  }
}

/*---------------------------galleryHomeDiv-----------------------------*/

.galleryHomeDiv {
  /* conteiner div for title, photo and button link gallery  */
  border-right: 1px solid rgb(225, 225, 225, 0.5);
  height: inherit;
  width: -moz-available;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

@media (min-width: 481px) and (max-width: 768px) {
  .galleryHomeDiv {
    display: flex;
    border-bottom: 1px solid rgb(225, 225, 225, 0.5);
  }
}

@media (min-width: 320px) and (max-width: 481px) {
  .galleryHomeDiv {
    display: flex;
    border-bottom: 1px solid rgb(225, 225, 225, 0.5);
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .galleryHomeDiv > h3 {
    font-size: 16px;
  }
}

/*--------------------------------------------------------*/

.linkPhotoHome {
  border: 1px solid white;
  height: 150px;
}

.linkPhotoHome img {
  /* mapped photo inside link gallery   */
}

@media (min-width: 320px) and (max-width: 480px) {
  .linkPhotoHome img {
    height: 150px;
  }
}

.pozadina {
  /* div that contains white color on hover  */
  height: 100%;
  width: 100%;
}
.pozadina:hover {
  background-color: rgb(225, 225, 225, 0.1);
  transition: all 0.8s;
}
/* .singleNavLink {
  /* border: none; */
/* padding: 0 15px 0 15px;
} */

/* .singleNavLink:hover {
  border-bottom: 1px solid white;
  transition: 0.5s ease;
} */

li {
  color: white;
  display: inline-block;
  margin: 10px;
}
li:after {
  display: block;
  content: "";
  border-bottom: solid 1px white;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

li.fromLeft:after {
  transform-origin: 100% 50%;
}
li.fromLeft:hover:after {
  transform: scaleX(1);
  transform-origin: 0% 50%;
}

/*---------------------------Cover photo for nature page-----------------------------*/

.natureImageCover {
  /* div for nature gallery cover photo  */
  background-image: url("/img/DSC_00871.jpg");
  background-size: cover;
  height: 100vh;
  width: auto;
  border: solid 10px white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
.animalsImageCover{
  background-image: url("/img/DSC_4734.jpg");
  background-size: cover;
  height: 100vh;
  width: auto;
  border: solid 10px white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

@media (min-width: 320px) and (max-width: 480px) {
  .animalsImageCover {
    height: 250px;
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .animalsImageCover img {
    height: 250px;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
}

.urbanImageCover{
  background-image: url("/img/yyknkuk4hcbkcomkshyf.jpg");
  background-size: cover;
  height: 100vh;
  width: auto;
  border: solid 10px white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

@media (min-width: 320px) and (max-width: 480px) {
  .urbanImageCover {
    height: 250px;
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .urbanImageCover img {
    height: 250px;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
}

.natureImageCover h3 {
  color: white;
  font-family: "Montserrat";
  font-size: 1.5em;
  font-weight: 900;
}

.natureImageCover p {
  color: white;
  font-family: "Montserrat";
  font-weight: 300;
}

.natureImageCover img {
  /* nature gallery cover photo  */
  height: 100vh;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

@media (min-width: 320px) and (max-width: 480px) {
  .natureImageCover {
    height: 100vh;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .natureImageCover img {
    height: 100vh;
  }
}
/*---------------------------Titles for all gallery pages-----------------------------*/

.naturePageTitle {
  padding: 50px;
}
.animalsPageTitle {
  padding: 50px;
}
.urbanPageTitle {
  padding: 50px;
}

.proba {
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

/*---------------------------Cover photo for urban page-----------------------------*/

.urbanImageCover {
  background-size: cover;
  height: 100vh;
  width: auto;
  border: solid 10px white;
}

.urbanImageCover img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

@media (min-width: 320px) and (max-width: 480px) {
  .urbanImageCover {
    height: 250px;
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .urbanImageCover img {
    height: 250px;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
}

/*---------------------------contactForm-----------------------------*/

.contactForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 481px) and (max-width: 768px) {
  .contactForm {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .contactForm h3 {
    font-size: 16px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .contactForm p {
    font-size: 14px;
  }
}

/*---------------------------form fields-----------------------------*/

@media (min-width: 481px) and (max-width: 768px) {
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
    /* width: 90%; */
  }
}

.horizontalLine {
  width: 30px;
  color: #7a7a7a;
}

/*--------------------contact form button------------------------------*/

.contactFormButton {
  background-color: black;
  color: white;
  border: 1px solid black;
  width: 100px;
  height: 40px;
  cursor: pointer;
}

.contactFormButton:hover {
  background-color: white;
  color: black;
  transition: 0.5s ease;
}

/*--------------------------footer------------------------------*/

.footer {
  min-height: 350px;
  width: auto;
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer p {
  color: gray;
  font-size: 12px;
}

.footerList {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0;
  cursor: pointer;
}

.footer img {
  height: 60px;
  width: 60px;
  margin: 20px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .footerList {
    display: flex;
    flex-direction: column;
  }
}

.footerContainer {
  display: flex;
  width: 100%;
}

.footerListContainer {
  display: flex;
}

/*-------------------------social links div in footer-------------------------------*/

@media (min-width: 320px) and (max-width: 480px) {
  .socialLinks p {
    display: none;
  }
}

.socialLinks {
  display: flex;
  flex-direction: column;
}

.socialLinks svg {
  filter: invert(100%);
}

.socialLinks h5 {
  color: gray;
}

.svgLinkovi {
  display: flex;
  justify-content: center;
  width: 100%;
}

.svgLinkovi svg {
  padding: 20px 10px 20px 10px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .svgLinkovi {
    padding-top: 20px;
    padding-bottom: 50px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .socialLinks svg {
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
  }
}

/*-------------------------Mobile menu-------------------------------*/

@media (min-width: 481px) {
  .longMenu {
    display: none;
    z-index: 999;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .longMenu {
    position: absolute;
    right: 43%;
  }
}

.copyright {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: 12px;
}

.error {
  height: 100vh;
}

.error button {
  width: 100px;
  height: 35px;
  background: black;
  border: 1px solid black;
  color: white;
}

.error h2 {
  color: #7a7a7a;
  font-size: 40px;
  font-weight: 100;
}
/* 
@media (min-width: 320px) and (max-width: 480px) {
  .mobileMenu {
    height: 95%;
    width: 95%;
  }
}

@media (max-width: 480px) {
  .mobileMenu {
    height: 95%;
    width: 95%;
  }
} */

.closeLongMenu {
  height: 30px;
  position: relative;
}

.mobileMenuOpening {
  max-width: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  background-color: white;
  box-shadow: 0px 0px, 18px 0px rgba(0, 0, 0, 0.75);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 120vh;
  z-index: 1;
  font-family: "Times New Roman", Times, serif;
  animation: openMenuModal 1s ease;
}

@keyframes openMenuModal {
  0% {
    opacity: 0.5;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.mobileMenuContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-bottom: 200px;
}

.mobileMenuHr {
  border: 0;
  border-bottom: 1px dashed #ccc;
  background: #999;
  width: 100%;
}

.modalButton {
  position: absolute;
  right: 8%;
  top: 10%;
  background-color: white;
  border: none;
  color: #7a7a7a;
  font-size: 23px;
}

.modalList {
  font-size: 35px;
  font-weight: 100;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin-left: 0;
  padding-left: 0;
  filter: contrast(60%);
}

.modalList > li {
  font-weight: bold !important;
  color: black;
}

.modalMenu {
  position: fixed;
  top: 8px;
  right: 15px;
  z-index: 999;
}

.firstMenuLineClosed {
  width: 30px;
  height: 3px;
  background-color: white;
  margin: 6px 0;
  transform: rotate(0deg);
  transition: 0.5s ease;
  position: relative;
  top: 0px;
}

.firstMenuLineOpen {
  background-color: gray;
  width: 30px;
  height: 3px;
  margin: 6px 0;
  transform: rotate(45deg);
  transition: 0.5s ease;
  position: relative;
  top: 4px;
}

.secondMenuLineClosed {
  width: 30px;
  height: 3px;
  background-color: white;
  margin: 6px 0;
  transform: rotate(0deg);
  transition: 0.5s ease;
  position: relative;
  bottom: 0px;
}

.secondMenuLineOpen {
  background-color: gray;
  width: 30px;
  height: 3px;
  margin: 6px 0;
  transform: rotate(-45deg);
  transition: 0.5s ease;
  position: relative;
  bottom: 4px;
}

.thirdMenuLineClosed {
  width: 30px;
  height: 3px;
  background-color: white;
  margin: 6px 0;
  transition: 0.5s ease;
}

.thirdMenuLineOpen {
  width: 0px;
  height: 3px;
  background-color: gray;
  margin: 6px 0;
  transition: 0.5s ease;
}

.modalSvg {
  width: 70%;
  display: flex;
  justify-content: space-evenly;
  margin: 10px 0px 10px 0;
  filter: contrast(50%) brightness(150%);
}

.svgContainer {
  border: 1px solid gray;
  width: 60px;
  height: 60px;
}

.svgContainer svg {
  position: relative;
  top: 25%;
}

.logoInMenu {
  width: 60px;
  height: 60px;
  margin: 10px;
}

.modalContainer {
  max-width: 60%;
  width: 60%;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  background-color: white;
  box-shadow: 0px 0px, 18px 0px rgba(0, 0, 0, 0.75);
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/*-------------------------Image Modal-------------------------------*/

.overlay {
  background-color: #eeeeee;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  bottom: 0;
  justify-content: space-evenly;
  animation: openPhotoZoom 400ms;
}

@keyframes upToDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes openPhotoZoom {
  from {
    transform: scale(0.3);
  }
  to {
    transform: scale(1);
  }
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

@media (max-width: 480px) {
  .overlay {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.overlay img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  margin-top: 70px;
  animation: scrollPhoto 500ms;
}

.openedImage {
  display: block;
  max-width: 60%;
  max-height: 80%;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  margin-top: 70px;
  animation: scrollPhoto 500ms;
}

@keyframes scrollPhoto {
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

@media (max-width: 480px) {
  .overlay img {
    max-width: 95%;
    max-height: 95%;
    position: relative;
    top: 25px;
  }
}

.imageContaineer {
}

.overlay > span {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 30px;
  color: #000000;
  z-index: 999;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.overlay > div > h3 {
  color: #000000;
  font-size: 2.5vw;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

@media (max-width: 480px) {
  .overlay > div > h3 {
    font-size: 5vw;
  }
}

.overlay > div > p {
  color: #000000;
  font-size: 1.5vw;
  font-family: "Montserrat", sans-serif;
}

@media (max-width: 480px) {
  .overlay > div > p {
    font-size: 4vw;
  }
}

.overlay-arrows_left {
  display: flex;
  background-color: none;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  left: 0;
  z-index: 999;
  color: gray;
}

.overlay-arrows_left svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right {
  display: flex;
  background-color: none;
  justify-content: space-between;
  position: absolute;
  right: 0;
  width: 50px;
  height: 50px;
  top: 50%;
  z-index: 999;
  color: gray;
}

.modalImageDetails {
  width: 30%;
}

@media (max-width: 480px) {
  .modalImageDetails {
    width: 90%;
    margin-top: 30px;
  }
}

.updatePhoto {
  border-radius: 10px;
  height: 400px;
  width: 400px;
  background-color: white;
  position: absolute;
  top: 30%;
  left: 40%;
  font-size: 30px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.updatePhoto > form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 300px;
  height: 150px;
}

.updatePhotoButton {
  height: 40px;
  width: 100px;
  border: none;
  background-color: black;
  color: white;
  text-align: center;
}

.updatePhoto > p {
  margin: 0px;
  position: absolute;
  right: 10px;
  top: 0px;
}

.dashboard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dashRight {
  width: 85%;
  height: 700px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #dbdbd3;
}

.dashLeft {
  background-color: #3061cc;
  color: white;
  width: 250px;
}

.dashPhotoContainer {
  width: 250px;
  height: 150px;
}

.dashPhoto {
  height: 100px;
  width: 200px;
  display: flex;
  overflow: hidden;
}

.addPhotoButton {
  height: 30px;
  width: 80px;
  border: none;
  background-color: black;
  color: white;
  margin: 20px;
}

.dashList {
  list-style-type: none;
  cursor: pointer;
}

.dashList > li {
  font-size: 22px;
  height: 40px;
  border-bottom-color: black;
}

.categorySelect {
  display: flex;
  justify-content: space-evenly;
}

.deleteButtons {
  height: 40px;
  width: 100px;
  border: none;
  background-color: black;
  color: white;
  margin: 0 20px 0 20px;
}
.logOut {
  position: absolute;
  top: 20px;
  right: 50px;
  border: 1px solid black;
  background-color: white;
  height: 30px;
  width: 70px;
}

.mountainsAlbum {
  background-image: url("../img/DSC_1509111-Pano.jpg");
  font-family: "Montserrat";
  height: 90vh;
  background-size: cover;
  repeat: no-repeat;
  border: 10px solid white;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.albumModal {
  background-color: white;
  height: 80%;
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: fade-in 1500ms;
  z-index: 997;
  cursor: pointer;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@media (max-width: 480px) {
  .mountainsAlbum {
    display: flex;
    flex-direction: column;
    align-items: space-around;
    height: 90vh;
    background-size: cover;
    z-index: 0;
  }
}

.albumModal {
  background-color: white;
  height: 80%;
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: fade-in 1500ms;
  z-index: 997;
  cursor: pointer;
}

.albumModal h3 {
  font-family: "Montserrat";
  font-weight: 900;
  color: black;
}

.albumModal p {
  width: 70%;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}

@media (max-width: 480px) {
  .albumModal {
    height: 50%;
    width: 90%;
  }
}

.forestAlbum {
  background-image: url("../img/DSC_0409(1).jpg");
  font-family: "Montserrat";
  height: 90vh;
  background-size: cover;
  repeat: no-repeat;
  border: 10px solid white;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media (max-width: 480px) {
  .forestAlbum {
    display: flex;
    flex-direction: column;
    align-items: space-around;
    height: 90vh;
    background-size: cover;
  }
}

.gocGallery {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  width: 90%;
}

.gocGallery > img {
  width: 20%;
  height: 20%;
  border: 4px solid white;
  &:hover {
    transform: scale(1.1);
  }
}

.gocGalleryPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.gocGalleryPage h3 {
  font-family: "Montserrat";
  font-weight: 900;
}

.gocGalleryPage p {
  font-family: "Montserrat";
}

.albumDescription {
  width: 70%;
  margin: 30px;
  text-align: justify;
}

@media (max-width: 600px) {
  .albumDescription {
    width: 90%;
  }
}

.aboutMeDescription {
  width: 70%;
  text-align: justify;
  font-family: Montserrat;
}

@media (max-width: 600px) {
  .aboutMeDescription {
    width: 90%;
  }
}

.aboutMeHead {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #54595f;
  font-family: "Montserrat";
  padding: 80px 0px 80px 0px;
}

.hrDevider {
  border: 0;
  border-bottom: 1px dashed #ccc;
  background: #999;
  width: 100px;
}

.hrDeviderWhite {
  border: 0;
  border-bottom: 1px dashed white;
  background: #999;
  width: 100px;
}

.aboutLandscape {
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  border: 5px solid white;
}

.aboutLandscapeMobile {
  width: 100%;
  height: 300px;
}

.aboutContainer {
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: Montserrat;
}

.aboutHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.aboutContainerMobile {
  color: gray;
  font-family: Montserrat;
  width: 90%;
  margin: 30px 0px 30px 0px;
}

.headName {
  color: white;
  font-size: 1.7em;
  font-family: Montserrat;
}

.headNameMobile {
  color: gray;
  font-family: Montserrat;
}

.aboutDescriptionText {
  font-size: 1.5em;
}

.aboutDescriptionTextMobile {
  color: gray;
}

/* ------------------------------------- */
/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
} */
.heading {
    text-align: center;
    font-size: 2.0em;
    letter-spacing: 1px;
    padding: 40px;
    color: white;
}

.gallery-image {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: white;
}

.actual-img {
  height: 250px;
  width: 350px;
  transform: scale(1.0);
  transition: transform 0.4s ease;
}

.img-box {
  box-sizing: content-box;
  margin: 2px;
  height: 250px;
  width: 350px;
  overflow: hidden;
  display: inline-block;
  color: white;
  position: relative;
  background-color: white;
}

.caption {
  position: absolute;
  bottom: 5px;
  opacity: 0.0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.transparent-box {
  height: 250px;
  width: 350px;
  background-color:rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  transition: background-color 0.3s ease;
}

.img-box:hover img { 
  transform: scale(1.1);
}

.img-box:hover .transparent-box {
  background-color:rgba(0, 0, 0, 0.5);
}

.img-box:hover .caption {
  transform: translateY(-20px);
  opacity: 1.0;
}

.img-box:hover {
  cursor: pointer;
}

.caption > p:nth-child(2) {
  font-size: 0.8em;
}

.opacity-low {
  opacity: 0.5;
}

.aboutCoverImage{
  width: 100%;
  height: 500px;
  object-fit: cover;
}
