@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200&family=Rubik:wght@400;500;700&display=swap");

.blogCover {
  background-image: url("../../images/DSC_2523.jpg");
  height: 50vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blogCoverHr {
  margin-top: 20px;
  border-bottom: 1px solid white;
  width: 300px;
}

.blogCover h2 {
  margin: 0;
  color: white;
  font-family: Montserrat;
}

.blogCover h4 {
  width: 60%;
  color: white;
  font-family: Montserrat;
}

.kategorije {
  margin: 30px 0px;
}

.kategorije button {
  background-color: transparent;
  color: gray;
  border: 1px solid gray;
  margin: 5px 5px 5px 5px;
  border-radius: 15px;
  padding: 5px 10px;
  text-align: center;
  cursor: pointer;
}

.kategorije button:hover{
  background-color: #5dbea3;
  color: white;
  border: 1px solid #5dbea3 ;
  transition: all 0.3s;
}

.blogList {
  display: flex;
  justify-content: center;
  width: 100vw;
}

.cardsList {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 650px) {
  .cardsList {
    justify-content: center;
  }
}

.mostPopular {
  margin-left: 30px;
}

@media (max-width: 650px) {
  .mostPopular {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 40px 0px;
  }
}

.mostPopular h5 {
  margin: 0;
  text-align: start;
}

.mostPopularList {
  display: flex;
  text-align: start;
  align-items: center;
  margin-top: 20px;
}

.mostPopularList img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.mostPopularList p {
  color: gray;
  font-size: 13px;
  margin: 4px 4px 4px 0px;
}

.mostPopularList h6 {
  font-size: 13px;
  margin: 0;
  width: 200px;
}

.mostPopularList > div {
  margin-left: 15px;
}

.mostPopularHr {
  margin-top: 20px;
  border-bottom: 1px solid gray;
  width: 300px;
}

@media (max-width: 650px) {
  .blogList {
    flex-direction: column;
    justify-content: center;
  }
}

.likesAndComments{
  display: flex;
  color: gray;
}

.likesAndComments div p{
  margin: 0
}

.likesAndComments svg{
  margin: 0px 5px;
}

.like{
  display: flex;
  flex-direction: column;
  justify-content:center;
  text-align: center;
}

/*________________________________*/

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
* {
  box-sizing: border-box;
}
/* body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background-color: #f7f8fc;
  font-family: "Roboto", sans-serif;
  color: #10182f;
} */
.blogContainer {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.card {
  margin: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 300px;
}
.card-header img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  min-height: 250px;
  text-align: start;
}

.tag {
  background: #cccccc;
  border-radius: 50px;
  font-size: 12px;
  margin: 0;
  color: #fff;
  padding: 2px 10px;
  text-transform: uppercase;
  cursor: pointer;
}
.tag-teal {
  background-color: #47bcd4;
}
.tag-purple {
  background-color: #5e76bf;
}
.tag-pink {
  background-color: #cd5b9f;
}

.card-body p {
  font-size: 13px;
  margin: 0 0 40px;
}

.authorAndDate {
  display: flex;
  margin-top: auto;
}
.user {
  display: flex;
  margin-top: auto;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.user img {
  /* border-radius: 50%; */
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.user-info h5 {
  margin: 0;
}
.user-info small {
  color: #545d7a;
}
